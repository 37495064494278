import React, { useState } from "react"

import NormalLogo from "../../images/logo.png"
import Heart from "../../../src/images/benefiz/heart.svg"
import BlackWhite from "../../../src/images/benefizOpen/Team.jpg"
import Markt from "../../../src/images/benefizOpen/Markt.jpg"
import Konzert from "../../../src/images/benefizOpen/Concert.jpg"
import Wald from "../../../src/images/benefizOpen/Wald.jpeg"
import Smile from "../../../src/images/benefizOpen/TukTuk.jpg"
import Kite from "../../../src/images/benefizOpen/Kinderlauf.jpeg"
import DownArrow from "../../../src/images/benefiz/DownArrow.svg"
import Menschen from "../../../src/images/benefiz/Menschen.svg"
import Dog from "../../../src/images/benefiz/Hund_Tiere.svg"
import Plant from "../../../src/images/benefiz/Pflanze_Umwelt.svg"
import Ball from "../../../src/images/benefiz/Ball_Sport.svg"
import Fire from "../../../src/images/benefiz/Feuerwehr.svg"
import RedHeart from "../../../src/images/benefiz/Herz_Sonstiges.svg"
import LeafBottom from "../../../src/images/benefiz/Blaetter_2.png"
import Footer from "../../components/layout/footer"
import Generationslauf from "../../../src/images/benefiz/Generationslauf.jpg"


import "../../styles/index.scss"
import "./index.css"
import BackgroundImage from "gatsby-background-image"
import { graphql } from "gatsby"
import styled from "styled-components"

const Benefiz = ({ _path, data }) => {
  const [isAnimalsOpen, setAnimalsOpen] = useState(false)
  const [isPeopleOpen, setPeopleOpen] = useState(false)
  const [isUmweltOpen, setUmweltOpen] = useState(false)
  const [isFireOpen, setFireOpen] = useState(false)
  const [isSportOpen, setSportOpen] = useState(false)
  const [isSpecialsOpen, setSpecialsOpen] = useState(false)

  const [conditionalOverflow, setConditionalOverflow] = useState(false)

  const openAnimals = () => {
    setAnimalsOpen(true)
  }

  const closeAnimals = () => {
    setAnimalsOpen(false)
  }

  const openPeople = () => {
    setPeopleOpen(true)
  }

  const closePeople = () => {
    setPeopleOpen(false)
  }

  const openUmwelt = () => {
    setUmweltOpen(true)
  }

  const closeUmwelt = () => {
    setUmweltOpen(false)
  }

  const openFire = () => {
    setFireOpen(true)
  }

  const closeFire = () => {
    setFireOpen(false)
  }

  const openSport = () => {
    setSportOpen(true)
  }

  const closeSport = () => {
    setSportOpen(false)
  }

  const openSpecials = () => {
    setSpecialsOpen(true)
  }

  const closeSpecials = () => {
    setSpecialsOpen(false)
  }

  return (
    <div className="benefiz-container">
      {/*//nav section*/}
      <div className="alternate-nav-container">
        <div className="logo-container">
          <img src={NormalLogo} width="50px" height="50px" alt={"logo"} />
          <p>Red Oak Consulting</p>
        </div>

        <div className="link-container">
          <a href={"https://www.red-oak-consulting.com/"} target={"_blank"} name="home">
            ← Zurück zu den Oakies
          </a>
        </div>

      </div>

      {/*//wir haben das Herz section*/}
      <div className="banner-container">
        <div className="left">
          <p>Wir haben das Herz.</p>
        </div>
        <img
          src={Heart}
          width={"70px"}
          height={"70px"}
          alt={"heart"}
          style={{ zIndex: 50, position: "absolute" }}
        />
        <BackgroundImage className="right" fluid={data.indexImage.childImageSharp.fluid}>
          <p>Wir haben die Mittel.</p>
        </BackgroundImage>
      </div>

      {/*//page section*/}
      <div className="main-content-column">

          <div className="main-content-row">
                <img src={BlackWhite} alt={"team"} />
                  <div className="red-rectangle"/>
                <div className="text-on-right">
                    <h2>… und die Passion, Dinge zu verändern.</h2>
                    <p>
                      Sowohl in unserer Arbeit in der Softwareentwicklung, in unserem Oakie-Dasein
                      als auch in der Gesellschaft.
                    </p>
                    <p style={{ marginTop: "35px" }}>
                      Wir sind davon überzeugt, dass wir Vereine, Organisationen und Personen
                      bestärken können, in dem was sie tun – nämlich die Welt ein Stück besser
                      machen. Genau da knüpfen wir an. Wir Oakies haben die Möglichkeit, genau diese
                      sozialen Projekte zu fördern und aus der Wirtschaft etwas in die Gesellschaft
                      zu bringen.
                    </p>
                    <p style={{ marginTop: "35px" }}>
                      Was wäre die Welt ohne diejenigen, die sich für andere einsetzen?
                    </p>
                </div>
          </div>

          <div className="arrow-section" >
              <h2>
                  Wir nutzen unser Herz,
                  <br />
                  unsere Mittel und unsere Passion.
              </h2>
                <img className="arrow-down"  src={DownArrow} alt={"arrowDown"}/>
          </div>

          <div className="main-content-row reverse">
                <div className="img-on-right">
                    <h2>
                      Vereinachtsmarkt –<br />
                      Wir machen kleine Vereine ganz groß
                    </h2>
                    <p>
                      Der Vereinachtsmarkt ist ein Markt, auf dem Unternehmen und Vereine aus der
                      Region Braunschweig mit einem eigenen Stand teilnehmen. Ob Verkauf, Musik oder
                      Spiel - jeder Teilnehmer wird dabei von Sponsoren unterstützt, um mit dem
                      Erlös ein Herzensanliegen zu finanzieren. Ziel ist die Vernetzung von
                      Braunschweiger Wirtschaft mit lokalen Vereinen, die Förderung sozialer
                      Projekte und ein gemütliches Beisammensein.
                    </p>
                    <p style={{ marginTop: "35px" }}>
                      Erstmals fand der Vereinachtsmarkt im Juni 2022 statt, mit vier Vereinen, 3
                      Unternehmen, mehr als 300 Besucher und einer Spendensumme von rund 20.000 €.
                    </p>
                    <a className="link" href={"https://www.vereinachtsmarkt.de/"} target={"_blank"}>
                      Mehr Infos zum Vereinachtsmarkt
                    </a>
                </div>
                <div className="red-rectangle"/>
                <img src={Markt} alt={"team"}/>
          </div>

              <div className="arrow-section" >
                <img src={DownArrow}  alt={"arrowDown"} className="arrow-down"/>
              </div>

          <div className="main-content-row">
                <img src={Generationslauf} alt={"generation"}/>

                  <div className="red-rectangle"/>

                <div className="text-on-right">
                    <h2>Unser Generationenlauf</h2>
                    <p>
                        Seit 2023 veranstalten wir gemeinsam mit der ProSenis GmbH, der Deerstone GmbH und der Ecclesia Gemeinde Braunschweig e.V. jährlich einen Generationenlauf. Diese Benefizveranstaltung bringt 2er-Teams aus Pflegeheimbewohnern und Kindern zusammen, die gemeinsam einen Hindernisparcours meistern. Im Vordergrund steht das Teamgefühl: Gemeinsam starten und das Ziel erreichen – ob zu Fuß, mit Rollator oder Rollstuhl. Angehörige und Freunde sponsern die Teams.
                    </p>
                    <p  style={{ marginTop: "35px" }}>
                        Die Veranstaltung wird durch Sponsorengelder finanziert, die Essen, Getränke und Attraktionen ermöglichen. Die Haupteinnahmen werden an lokale Organisationen gespendet. Bisher konnten über 15.000€ an Vereine in Braunschweig gespendet werden. Der Lauf fördert die Gemeinschaft und stärkt die Verbindung der Generationen.                    </p>

                    <a className="link" href={"https://www.generationenlauf.de/bilderstrecke"} target={"_blank"}>
                        Mehr Impressionen zum Generationenlauf
                    </a>
                </div>
          </div>

          <div className="arrow-section" >
              <img src={DownArrow}  alt={"arrowDown"} className="arrow-down"/>
          </div>

          <div className="main-content-row reverse">
              <div className="img-on-right">
                    <h2>Unser Oakie-Wald</h2>
                    <p>
                      Unser kleiner Oakie-Wald im Mischwald in Nicaragua wird seit Ende des Jahres
                      2022 mithilfe von 10.000 € aufgeforstet. Auf dem Land des Kleinbauern Daniel
                      Fargas Mendoza werden 4.008 Baumsetzlinge gepflanzt, die zu einem Mischwald
                      heranwachsen und in den nächsten 50 Jahren rund 720 Tonnen CO
                      <sub style={{ verticalAlign: "sub", fontSize: "smaller" }}>2</sub> einbinden
                    </p>
                    <a href={"https://www.primaklima.org/projekte/unsere-projekte/projekt/red-oak-consulting-unternehmenswald-in-nicaragua-272/"}
                      target={"_blank"}
                      className="link"
                    >Hier geht es zum Oakie-Wald
                    </a>
                </div>
              <div className="red-rectangle"/>
              <img src={Wald} alt={"team"}/>
          </div>

          <div className="arrow-section" >
              <img src={DownArrow}  alt={"arrowDown"} className="arrow-down"/>
          </div>

          <div className="main-content-row">
              <img src={Kite} alt={"team"} />
              <div className="red-rectangle"/>
              <div className="text-on-right">
                    <h2>CMD Kinderlauf 19. Februar 2023 in Indien</h2>
                    <p>
                      Wir haben den ersten CMD Kinderlauf in Indien mit einer Summe von 4800 €
                      unterstützt. Über 1000 Kinder liefen für den guten Zweck. Der Sponsorenlauf
                      fand in elf Heimen in Indien statt, um diese bei der Finanzierung eines
                      besonderen Herzensanliegens zu unterstützen. Die Kinder wurden von Pateneltern
                      in Deutschland und der Schweiz mit Festbeträgen gesponsert.
                    </p>
                    <a className="link" href={"https://cmd-kinderlauf.de/"} target={"_blank"}>
                      Mehr Infos zum CMD Kinderlauf
                    </a>
                </div>
              </div>

          <div className="arrow-section" >
              <img className="arrow-down"  src={DownArrow} alt={"arrowDown"}/>
          </div>

          <div className="main-content-row reverse">
              <div className="img-on-right">
                  <h2>Tuk Tuk in Indien</h2>
                    <p>
                      Über einen befreundeten Kollegen der Deerstone GmbH, der Kontakte zu Paten von
                      Kindern in Indien hat, wissen wir um die Situation vor Ort. Tuk Tuks sind für
                      die Einheimischen lebensnotwendige Mittel, um zum Beispiel die Versorgung der
                      Kinder zu gewährleisten. Sie kommen damit zum Beispiel zu weiter entfernten
                      Schulen. Mit der Spende eines Tuk Tuks im Wert von ca. 8000 € konnte Red Oak
                      an der Stelle helfen, wo Hilfe am besten und effizientesten eingesetzt wird.
                    </p>
                </div>
              <div className="red-rectangle"/>
              <img src={Smile} alt={"team"}/>
          </div>

          <div className="arrow-section" >
              <img className="arrow-down"  src={DownArrow} alt={"arrowDown"}/>
          </div>

          <div className="main-content-row">
              <img src={Konzert} alt={"team"}/>

              <div className="red-rectangle"/>

              <div className="text-on-right">
                  <h2>15. Juli 2023 – Benefizkonzert in Geitelde</h2>
                  <p>
                      Auch wenn der Vereinachtsmarkt im Jahr 2023 nicht stattfindet, unterstützen
                      wir mit unseren Möglichkeiten zum Beispiel das diesjährige Waldbühnen Open Air
                      in Geitelde (organisiert durch Dorfgemeinschaft Geitelde gemeinnütziger e.V.)
                      mit einer Tombola und Hüpfburg.
                  </p>
              </div>
          </div>
      </div>

            <div className="spenden-container">
                <div className="spenden-row">
                    <div className="spenden-left-col">
                        <h2>
                            Wir haben soziales Engagement fest eingeplant in unserer Unternehmensführung
                        </h2>
                        <p id="first-par">
                            Das soziale Engagement ist in der Red Oak Unternehmensführung auch für die
                            Mitarbeiter fest eingeplant. So bekommt jeder Mitarbeiter sein
                            Mitarbeiterbudget pro Jahr von 5.000 €. In erster Linie wird das Budget für
                            die individuelle Weiterbildung genutzt.
                        </p>
                        <p>
                            Am Ende jeden Jahres wird das nicht ausgegebene Budget an Organisationen und
                            Vereine der Wahl (der Mitarbeiter) gespendet – egal für Nachhaltigkeit,
                            Tierwohl, Kinder oder Sozialschwächere. Da, wo das Herz der Mitarbeiter
                            aufgeht, kann das Geld zugutekommen.
                        </p>
                    </div>
                    <div className="spenden-right-col" >
                        <h2>
                            Wer genauer sehen möchte, an wen und wie viel wir spenden,
                            <br />
                            kann hier mehr Infos bekommen:
                        </h2>
                        <div className="icon-container">
                            <div className="icon-row">
                                <div className="icon-box">
                                    <img src={Menschen} onClick={openPeople} alt="people"/>
                                    <p>Menschen</p>
                                    {isPeopleOpen && (
                                        <div className="lightbox-body-gray"  onClick={closePeople}>
                                            <div className="light-box-white">
                                                <div className="lightbox-head">
                                                    <img src={Menschen} alt={"arrowDown"} onClick={openPeople} />
                                                    <p>Menschen</p>
                                                </div>
                                                <h2>Kinderhospiz Löwenherz</h2>
                                                <p>800 €</p>

                                                <h2>Stiftung Hospizdienst Oldenburg</h2>
                                                <p>6.738,75 €</p>

                                                <h2>kinderlachen-oldenburg e.V.</h2>
                                                <p>2.250 €</p>

                                                <h2>Kinderhospiz Sternenbrücke</h2>
                                                <p>2.583,33 €</p>

                                                <h2>
                                                    Oldenburger Trauerbegleitung für Kinder und Jugendliche e.V.
                                                </h2>
                                                <p>2.233,33 €</p>

                                                <h2>Familienhörbuch gGmbH</h2>
                                                <p>3.759,25 €</p>

                                                <h2>Cura e.V</h2>
                                                <p>700 €</p>

                                                <h2>
                                                    BeKoS (Beratungs- und Koordinationsstelle für Selbsthilfegruppen)
                                                </h2>
                                                <p>1.554,4 €</p>

                                                <h2>Weihnachten für alle e.V.</h2>
                                                <p>3.006,2 €</p>

                                                <h2>Tafel Lilienthal Worpswede</h2>
                                                <p>74,13 €</p>

                                                <h2>LiBERO Selbsthilfegruppe e.V.</h2>
                                                <p>2.500 €</p>

                                                <h2>Cloppenburger Tafel e.V.</h2>
                                                <p>1.500 €</p>

                                                <h2>Herzenswünsche e.V.</h2>
                                                <p>3.250 €</p>

                                                <h2>Wunschliste e.V.</h2>
                                                <p>2.000 €</p>

                                                <h2>
                                                    Steenbeck-Gymnasium Cottbus (Förderverein)
                                                </h2>
                                                <p>5.210 €</p>

                                                <h2>St. Leo-Stift gemeinnützige GmbH</h2>
                                                <p>1.161,09 €</p>

                                                <h2>Stiftung Opferhilfe Niedersachsen</h2>
                                                <p>6.032,21 €</p>

                                                <h2>
                                                    Verein der Freunde des Elisabeth-Kinderkrankenhauses e.V.
                                                </h2>
                                                <p>1.000 €</p>

                                                <h2>Lebenshilfe Braunschweig</h2>
                                                <p>2.500 €</p>

                                                <h2>Deutsches Komitee fuer UNICEF e.V.</h2>
                                                <p>2.100 €</p>

                                                <h2>Waldkindergarten Braunschweig e.V.</h2>
                                                <p>1.000 €</p>

                                                <h2>Kita Lindenallee Jever</h2>
                                                <p>1.450 €</p>

                                                <h2>Braunschweiger Dschungel e.V. </h2>
                                                <p>1.500 €</p>

                                                <h2>Roundtable Osnabrueck</h2>
                                                <p>2.000 €</p>

                                                <h2>SbE-Team zwischen Harz und Heide e.V. - Einsatznachsorge für ehrenamtliche Einsatzkräfte</h2>
                                                <p>12.142,44 €</p>

                                                <h2>ECPAT Deutschland e.V.</h2>
                                                <p>6.000,00 €</p>

                                                <h2>KITA - Regionale Dienststelle Roffhausen</h2>
                                                <p>7.810,92 €</p>

                                                <h2>Familiencafé Empelde - Kirchenkreis Ronnenberg</h2>
                                                <p>8.000,00 €</p>

                                                <h2>Brot für die Welt</h2>
                                                <p>5.640,52 €</p>

                                                <h2>Hasentour e.V.</h2>
                                                <p>2.000,00 €</p>

                                                <h2>Cloppenburger Tafel e.V.</h2>
                                                <p>1.000,00 €</p>

                                                <h2>Oldenburger Straßenengel e.V.</h2>
                                                <p>500,00 €</p>

                                                <h2>August-Hermann-Francke Familienhilfe</h2>
                                                <p>6.396,50 €</p>

                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="icon-box">
                                    <img src={Dog} alt={"dog"} onClick={openAnimals} />
                                    <p>Tiere</p>
                                    {isAnimalsOpen && (
                                        <div className="lightbox-body-gray"  onClick={closeAnimals}>
                                            <div className="light-box-white">
                                                <div className="lightbox-head">
                                                    <img src={Dog} alt={"dog"}/>
                                                    <p>Tiere</p>
                                                </div>
                                                <h2>Tierheim Oldenburg</h2>
                                                <p>500,00 €</p>

                                                <h2>SOS-Fellnasen-BS-WF e.V.</h2>
                                                <p>500 €</p>

                                                <h2>Tierschutzverein Friesoythe und Umgebung e. V</h2>
                                                <p>1.365,00 €</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="icon-box">
                                    <img src={Plant} alt={"plant"} onClick={openUmwelt}/>
                                    <p>Umwelt</p>
                                    {isUmweltOpen && (
                                        <div  className="lightbox-body-gray"    onClick={closeUmwelt}>
                                            <div className="light-box-white">
                                                <div className="lightbox-head">
                                                    <img src={Plant} alt={"plant"}/>
                                                    <p>Umwelt</p>
                                                </div>
                                                <h2>Küste gegen Plastik e.V.</h2>
                                                <p>2.000 €</p>

                                                <h2>
                                                    Biologische Schutzgemeinschaft Hunte Weser-Ems e.V. (BSH)
                                                </h2>
                                                <p>1.500 €</p>

                                                <h2>Viva con Agua de Sankt Pauli e.V.</h2>
                                                <p>3.973,29 €</p>

                                                <h2>BUND Kreisgruppe Braunschweig</h2>
                                                <p>1.045 €</p>

                                                <h2>Dein Deichrad e.V.</h2>
                                                <p>1.902,87 €</p>

                                                <h2>Regionales Umweltzentrum Schortens</h2>
                                                <p>1.000,00 €</p>

                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className="icon-row bottom-icon-row">
                                <div className="icon-box">
                                    <img src={Fire} alt={"fire"} onClick={openFire}/>
                                    <p>Feuerwehr</p>
                                    {isFireOpen && (
                                        <div  className="lightbox-body-gray"  onClick={closeFire} >
                                            <div className="light-box-white">
                                                <div className="lightbox-head">
                                                    <img src={Fire}  alt={"fire"}/>
                                                    <p>Feuerwehr</p>
                                                </div>
                                                <h2>Freiwillige Feuerwehr Schöppenstedt</h2>
                                                <p>6.868,9 €</p>

                                                <h2>Förderverein der Freiwilligen Feuerwehr Barnstorf</h2>
                                                <p>1.750,00 €</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="icon-box">
                                    <img src={Ball} alt={"ball"} onClick={openSport}/>
                                    <p>Sport</p>
                                    {isSportOpen && (
                                        <div  className="lightbox-body-gray"  onClick={closeSport} >
                                            <div className="light-box-white">
                                                <div className="lightbox-head">
                                                    <img src={Ball} alt={"ball"}/>
                                                    <p>Sport</p>
                                                </div>
                                                <h2>
                                                    Eintracht Braunschweig Stiftung e.V.
                                                </h2>
                                                <p>800 €</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="icon-box">
                                    <img src={RedHeart} alt={"redheart"} onClick={openSpecials}/>
                                    <p>Sonstige</p>
                                    {isSpecialsOpen && (
                                        <div  className="lightbox-body-gray" onClick={closeSpecials} >
                                            <div className="light-box-white">
                                                <div className="lightbox-head">
                                                    <img src={RedHeart} alt={"red heart"}/>
                                                    <p>Sonstige</p>
                                                </div>
                                                <h2>
                                                    Verein zur Foerderung der Ev. Jugend in der Propstei Schoeppenstedt.
                                                </h2>
                                                <p>800 €</p>

                                                <h2>
                                                    Open Knowledge Foundation Deutschland e.V.
                                                </h2>
                                                <p>700 €</p>

                                                <h2>Wikimedia</h2>
                                                <p>50,27 €</p>

                                                <h2>Kath. Kirchengem. St. Petrus</h2>
                                                <p>2.000 €</p>

                                                <h2>CVJM</h2>
                                                <p>1.000 €</p>

                                                <h2>Freunde und Förderer des Städt. Klinikums Braunschweig e.V.</h2>
                                                <p>3.000,00 €</p>

                                                <h2>vspace.one e. V.</h2>
                                                <p>1.700,00 €</p>

                                                <h2>Freilicht- und Heimatmuseum Haselünne e. V.</h2>
                                                <p>1.000,00 €</p>

                                                <h2>Verein zur Förderung der ev. Jugendarbeit in der Propstei Schöppenstedt e.V.</h2>
                                                <p>1.781,49 €</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

      <h1 style={{ textAlign: "center", color: "black", marginTop: "60px", marginBottom: "60px" }}>
        Einfach machen!
      </h1>
      <Footer />
    </div>
  )
}

const StyledBackgroundSection = styled(Benefiz)``

export default StyledBackgroundSection

export const pageQuery = graphql`
  query {
    indexImage: file(relativePath: { eq: "benefizOpen/holz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
